import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { toast } from "sonner";
import useFetch from "../../../Component/Hooks/useFetch";
import DemenageurLoader from "../Loader/DemenageurLoader";

const EditWidgetDemenageur = ({ widgetId, userId }) => {
  const user = useSelector((state) => state.userReducer);

  const [isLoading, setIsLoading] = useState(false);
  const [widgetData, setWidgetData] = useState({
    isOnline: "online",
    type: "Widget Demenageur",
  });

  const { loading, data } = useFetch(
    `${process.env.REACT_APP_API_URL}/api/widgets/demenageur/getOneWidget/${widgetId}`
  );

  useEffect(() => {
    if (data) {
      setWidgetData(data.widget);
    }
  }, [data, widgetId]);

  const handleInputChange = (e) => {
    setWidgetData({ ...widgetData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/widgets/demenageur/edite/${widgetId}/user/${userId}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(widgetData),
        }
      );
      if (response.ok) {
        toast.success("Widget édité avec succès");
      } else {
        switch (response.status) {
          case 401:
            toast.error("Vous n'êtes pas connecté");
            break;
          case 400:
            toast.error("Attention, un widget existe déjà avec ce nom.");
            break;
          default:
            toast.error("Une erreur est survenue");
            break;
        }
      }
    } catch (error) {
      console.error("Error:", error);
      toast.error("Une erreur est survenue lors de la mise à jour.");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="p-4 w-full flex gap-10">
      {loading ? (
        <div className="w-[100%]">
          <DemenageurLoader />
        </div>
      ) : (
        <div className="w-full">
          {user.user.isPremium ? (
            <form
              onSubmit={handleSubmit}
              className="space-y-4 flex justify-center items-center mt-5"
            >
              <div
                className="shadow-2xl rounded p-9 pt-6 pb-8 mb-4 flex flex-col my-2 w-[80%] mt-2"
                style={{
                  backgroundColor: widgetData.backgroundColor || "#fff",
                  color: widgetData.labelColor || "#000",
                }}
              >
                <div className="-mx-3 md:flex mb-3">
                  <div className="md:w-full px-3 flex flex-col items-center justify-center">
                    <label
                      className="block uppercase tracking-wide text-grey-darker text-sm mb-2 text-center font-extrabold"
                      htmlFor="title"
                    >
                      Modifier le titre du simulateur *
                    </label>
                    <input
                      className="text-center block w-3/4 rounded-md border-0 p-2 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      id="title"
                      type="text"
                      name="title"
                      placeholder="Code sage - Entreprise"
                      value={widgetData.title}
                      onChange={handleInputChange}
                      required
                    />
                  </div>
                </div>

                <div className="-mx-3 md:flex mb-3">
                  <div className="md:w-full px-3 flex flex-col items-center justify-center">
                    <label
                      className="block uppercase tracking-wide text-grey-darker text-sm mb-2 text-center font-extrabold"
                      htmlFor="grid-password"
                    >
                      Afficher le prix ?
                    </label>
                    <select
                      className="text-center block w-3/4 rounded-md border-0 p-2 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      id="grid-password"
                      name="priceActive"
                      value={widgetData.priceActive}
                      onChange={handleInputChange}
                    >
                      <option value="true">Oui</option>
                      <option value="false">Non</option>
                    </select>
                  </div>
                </div>
                {widgetData?.priceActive === "true" ? (
                  <div className="-mx-3 md:flex mb-3">
                    <div className="md:w-full px-3 flex flex-col items-center justify-center">
                      <label
                        className="block uppercase tracking-wide text-grey-darker text-sm mb-2 text-center font-extrabold"
                        htmlFor="pricePerM3"
                      >
                        Prix au m3 *
                      </label>
                      <input
                        className="text-center block w-3/4 rounded-md border-0 p-2 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        id="pricePerM3"
                        type="number"
                        name="pricePerM3"
                        placeholder="Prix au m3"
                        value={widgetData.pricePerM3}
                        onChange={handleInputChange}
                        required
                      />
                    </div>
                  </div>
                ) : null}

                {widgetData?.priceActive === "true" ? (
                  <div className="-mx-3 md:flex mb-3">
                    <div className="md:w-full px-3 flex flex-col items-center justify-center">
                      <label
                        className="block uppercase tracking-wide text-grey-darker text-sm mb-2 text-center font-extrabold"
                        htmlFor="voyageVidePrice"
                      >
                        Tarif Voyage à vide *
                      </label>
                      <input
                        className="text-center block w-3/4 rounded-md border-0 p-2 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        id="voyageVidePrice"
                        type="number"
                        name="voyageVidePrice"
                        placeholder="Prix du voyage à vide"
                        value={widgetData.voyageVidePrice}
                        onChange={handleInputChange}
                        required
                      />
                    </div>
                  </div>
                ) : null}

                <div className="-mx-3 md:flex mb-3">
                  <div className="md:w-full px-3 flex flex-col items-center justify-center">
                    <label
                      className="block uppercase tracking-wide text-grey-darker text-sm mb-2 text-center font-extrabold"
                      htmlFor="btnColor"
                    >
                      Couleur du bouton
                    </label>
                    <input
                      className="text-center block w-3/4 rounded-md border-0 p-2 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      id="btnColor"
                      type="text"
                      name="btnColor"
                      placeholder="Couleur du bouton"
                      value={widgetData.btnColor}
                      onChange={handleInputChange}
                    />
                  </div>
                </div>

                <div className="-mx-3 md:flex mb-3">
                  <div className="md:w-full px-3 flex flex-col items-center justify-center">
                    <label
                      className="block uppercase tracking-wide text-grey-darker text-sm mb-2 text-center font-extrabold"
                      htmlFor="backgroundColor"
                    >
                      Couleur de fond
                    </label>
                    <input
                      className="text-center block w-3/4 rounded-md border-0 p-2 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      id="backgroundColor"
                      type="text"
                      name="backgroundColor"
                      placeholder="Couleur de fond"
                      value={widgetData.backgroundColor}
                      onChange={handleInputChange}
                    />
                  </div>
                </div>

                <div className="-mx-3 md:flex mb-3">
                  <div className="md:w-full px-3 flex flex-col items-center justify-center">
                    <label
                      className="block uppercase tracking-wide text-grey-darker text-sm mb-2 text-center font-extrabold"
                      htmlFor="labelColor"
                    >
                      Couleur du texte
                    </label>
                    <input
                      className="text-center block w-3/4 rounded-md border-0 p-2 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      id="labelColor"
                      type="text"
                      name="labelColor"
                      placeholder="Couleur du texte"
                      value={widgetData.labelColor}
                      onChange={handleInputChange}
                    />
                  </div>
                </div>

                <div className="-mx-3 md:flex mb-3">
                  <div className="md:w-full px-3 flex flex-col items-center justify-center">
                    <label
                      className="block uppercase tracking-wide text-grey-darker text-sm mb-2 text-center font-extrabold"
                      htmlFor="email"
                    >
                      Email de contact
                    </label>
                    <input
                      className="text-center block w-3/4 rounded-md border-0 p-2 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      id="contactMail"
                      type="email"
                      name="contactMail"
                      placeholder="Email du partenaire"
                      value={widgetData.contactMail}
                      onChange={handleInputChange}
                    />
                  </div>
                </div>
                <div className="flex items-center justify-center mt-6">
                  <button
                    type="submit"
                    className="bg-black text-white px-4 py-2 rounded-md hover:bg-gray-600 my-1 w-[200px] flex justify-center items-center gap-2"
                    style={{
                      backgroundColor: widgetData.btnColor || "#000",
                    }}
                  >
                    {isLoading ? "Chargement..." : "Éditer le widget"}
                  </button>
                </div>
              </div>
            </form>
          ) : null}
        </div>
      )}
    </div>
  );
};

export default EditWidgetDemenageur;

import React, { useEffect, useState } from "react";
import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import { Link } from "react-router-dom";
import {
  Card,
  CardHeader,
  Input,
  Typography,
  Button,
  CardBody,
  CardFooter,
  Tabs,
  TabsHeader,
  Tab,
  Menu,
  MenuHandler,
  MenuList,
  MenuItem,
} from "@material-tailwind/react";
import axios from "axios";
import ListingDemenagement from "./DemenageurWidget/ListingDemenagement";
import ListingTaxi from "./TaxiWidget/ListingTaxi";
import { toast } from "sonner";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faListOl } from "@fortawesome/free-solid-svg-icons";

const TABS = [
  { label: "Tous", value: "all" },
  { label: "Taxi-VTC", value: "Widget Taxi" },
  { label: "Déménagement", value: "Widget Demenageur" },
];

const ListWidget = ({ userId }) => {
  const [widgets, setWidgets] = useState([]);
  const [activeTab, setActiveTab] = useState("all");
  const [searchTerm, setSearchTerm] = useState("");
  const [loading, setLoading] = useState(true);

  // Initialisation des états pour les modals
  const [isModalOpen, setIsModalOpen] = useState({});
  const [isEditModalOpen, setIsEditModalOpen] = useState({});
  const [isCodeModalOpen, setIsCodeModalOpen] = useState({});
  const [isStatModalOpen, setIsStatModalOpen] = useState({});

  const fetchWidgets = async () => {
    setLoading(true);
    try {
      const taxiResponse = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/widgets/taxi/`
      );
      const demenageurResponse = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/widgets/demenageur/`
      );

      setWidgets([
        ...taxiResponse.data.widgets,
        ...demenageurResponse.data.widgets,
      ]);
    } catch (error) {
      console.error("Erreur lors de la récupération des widgets:", error);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchWidgets();
  }, []);

  // Filtrer les widgets en fonction de l'onglet actif et du terme de recherche
  const filteredWidgets = widgets.filter((widget) => {
    return (
      (activeTab === "all" || widget.type === activeTab) &&
      widget.title.toLowerCase().includes(searchTerm.toLowerCase())
    );
  });

  const toggleModal = (id) => {
    setIsModalOpen((prev) => ({ ...prev, [id]: !prev[id] }));
  };

  const toggleEditModal = (id) => {
    setIsEditModalOpen((prev) => ({ ...prev, [id]: !prev[id] }));
  };

  const toggleCodeModal = (id) => {
    setIsCodeModalOpen((prev) => ({ ...prev, [id]: !prev[id] }));
  };

  const toggleStatModal = (id) => {
    setIsStatModalOpen((prev) => ({ ...prev, [id]: !prev[id] }));
  };

  const handleDeleteWidgetTaxi = async (widgetId) => {
    try {
      const confirmed = window.confirm(
        "Êtes-vous sûr de vouloir supprimer ce widget?"
      );

      if (confirmed) {
        const response = await axios.delete(
          `${process.env.REACT_APP_API_URL}/api/widgets/taxi/delete/${widgetId}/user/${userId}`
        );

        if (response.status === 200) {
          setWidgets((prevWidgets) =>
            prevWidgets.filter((widget) => widget._id !== widgetId)
          );
          toast.success("Widget supprimé");
        } else {
          toast.error("Erreur lors de la suppression du widget");
        }
      }
    } catch (error) {
      console.error("Erreur:", error);
    }
  };

  const handleDeleteWidgetDemenagement = async (widgetId) => {
    try {
      const confirmed = window.confirm(
        "Êtes-vous sûr de vouloir supprimer ce widget?"
      );

      if (confirmed) {
        const response = await axios.delete(
          `${process.env.REACT_APP_API_URL}/api/widgets/demenageur/delete/${widgetId}/user/${userId}`
        );

        if (response.status === 200) {
          setWidgets((prevWidgets) =>
            prevWidgets.filter((widget) => widget._id !== widgetId)
          );
          toast.success("Widget supprimé");
        } else {
          toast.error("Erreur lors de la suppression du widget");
        }
      }
    } catch (error) {
      console.error("Erreur:", error);
    }
  };

  const TABLE_HEAD = [
    "Widget",
    "Type",
    "Statut",
    "Date de création",
    "Statistiques",
    "Visualiser",
    "Intégrer",
    "Editer",
    "Supprimer",
  ];

  return (
    <Card className="w-full">
      <CardHeader floated={false} shadow={false} className="rounded-none">
        <div className="mb-8 flex items-center justify-between gap-8">
          <div>
            <Typography variant="h5" color="blue-gray">
              <FontAwesomeIcon icon={faListOl} /> Liste des widgets
            </Typography>
            <Typography color="gray" className="mt-1 font-normal">
              Visualiser et modifier tous vos widgets
            </Typography>
          </div>
          <div className="flex shrink-0 flex-col gap-2 sm:flex-row p-7 relative">
            <Menu>
              <MenuHandler>
                <Button className="relative inline-flex items-center justify-center px-8 py-2 font-bold text-white transition-all duration-200 bg-gray-900 font-pj rounded-xl focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900">
                  Créer un widget
                </Button>
              </MenuHandler>
              <MenuList className="bg-white shadow-lg rounded-lg z-10">
                <MenuItem>
                  <Link
                    to="/createTaxiWidget"
                    className="block px-4 py-2 text-gray-800 hover:bg-gray-100"
                  >
                    Créer un widget Taxi
                  </Link>
                </MenuItem>
                <MenuItem>
                  <Link
                    to="/createDemenagementWidget"
                    className="block px-4 py-2 text-gray-800 hover:bg-gray-100"
                  >
                    Créer un widget Déménagement
                  </Link>
                </MenuItem>
              </MenuList>
            </Menu>
          </div>
        </div>
        <div className="flex flex-col items-center justify-between gap-4 md:flex-row">
          <Tabs value={activeTab} className="w-2/5">
            <TabsHeader>
              {TABS.map(({ label, value }) => (
                <Tab
                  key={value}
                  value={value}
                  onClick={() => setActiveTab(value)}
                >
                  {label}
                </Tab>
              ))}
            </TabsHeader>
          </Tabs>
          <div className="w-full md:w-72">
            <Input
              label="Rechercher"
              icon={<MagnifyingGlassIcon className="h-5 w-5" />}
              placeholder="Rechercher un widget"
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>
        </div>
      </CardHeader>
      <CardBody className="px-0">
        <table className="mt-4 w-full min-w-max table-auto text-center">
          <thead>
            <tr>
              {TABLE_HEAD.map((head) => (
                <th
                  key={head}
                  className="border-y border-blue-gray-100 bg-blue-gray-50/50 p-4"
                >
                  <Typography
                    variant="small"
                    color="blue-gray"
                    className="font-normal leading-none opacity-70"
                  >
                    {head}
                  </Typography>
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {activeTab === "all" && (
              <>
                <ListingTaxi
                  widgets={filteredWidgets.filter(
                    (widget) => widget.type === "Widget Taxi"
                  )}
                  loading={loading}
                  isModalOpen={isModalOpen}
                  isEditModalOpen={isEditModalOpen}
                  isCodeModalOpen={isCodeModalOpen}
                  isStatModalOpen={isStatModalOpen}
                  toggleModal={toggleModal}
                  toggleEditModal={toggleEditModal}
                  toggleCodeModal={toggleCodeModal}
                  toggleStatModal={toggleStatModal}
                  handleDeleteWidgetTaxi={handleDeleteWidgetTaxi}
                />
                <ListingDemenagement
                  widgets={filteredWidgets.filter(
                    (widget) => widget.type === "Widget Demenageur"
                  )}
                  loading={loading}
                  isModalOpen={isModalOpen}
                  isEditModalOpen={isEditModalOpen}
                  isCodeModalOpen={isCodeModalOpen}
                  isStatModalOpen={isStatModalOpen}
                  toggleModal={toggleModal}
                  toggleEditModal={toggleEditModal}
                  toggleCodeModal={toggleCodeModal}
                  toggleStatModal={toggleStatModal}
                  handleDeleteWidgetDemenagement={
                    handleDeleteWidgetDemenagement
                  }
                />
              </>
            )}
            {activeTab === "Widget Taxi" && (
              <ListingTaxi
                widgets={filteredWidgets.filter(
                  (widget) => widget.type === "Widget Taxi"
                )}
                loading={loading}
                isModalOpen={isModalOpen}
                isEditModalOpen={isEditModalOpen}
                isCodeModalOpen={isCodeModalOpen}
                isStatModalOpen={isStatModalOpen}
                toggleModal={toggleModal}
                toggleEditModal={toggleEditModal}
                toggleCodeModal={toggleCodeModal}
                toggleStatModal={toggleStatModal}
                handleDeleteWidgetTaxi={handleDeleteWidgetTaxi}
              />
            )}
            {activeTab === "Widget Demenageur" && (
              <ListingDemenagement
                widgets={filteredWidgets.filter(
                  (widget) => widget.type === "Widget Demenageur"
                )}
                loading={loading}
                isModalOpen={isModalOpen}
                isEditModalOpen={isEditModalOpen}
                isCodeModalOpen={isCodeModalOpen}
                isStatModalOpen={isStatModalOpen}
                toggleModal={toggleModal}
                toggleEditModal={toggleEditModal}
                toggleCodeModal={toggleCodeModal}
                toggleStatModal={toggleStatModal}
                handleDeleteWidgetDemenagement={handleDeleteWidgetDemenagement}
              />
            )}
          </tbody>
        </table>
      </CardBody>
      <CardFooter className="flex items-center justify-between border-t border-blue-gray-50 p-4">
        <Typography variant="small" color="blue-gray" className="font-normal">
          Page 1 sur 1
        </Typography>
        <div className="flex gap-2">
          <Button variant="outlined" size="sm">
            Precedent
          </Button>
          <Button variant="outlined" size="sm">
            Suivant
          </Button>
        </div>
      </CardFooter>
    </Card>
  );
};

export default ListWidget;

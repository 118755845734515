import { useState } from "react";

const IframeCode = ({ widgetId }) => {
  const generatedIframeCode = `<iframe src="${process.env.REACT_APP_URL}/iframePage/demenagement/${widgetId}" frameborder="0" width="100%" height="600"></iframe>`;
  const [copied, setCopied] = useState(false);

  const copyToClipboard = () => {
    navigator.clipboard.writeText(generatedIframeCode);
    setCopied(true);
  };

  return (
    <>
      <div className="relative m-16 ">
        <button className="absolute py-1 px-3 -left-8 -top-2 -rotate-[10deg] border border-black black_border bg-black text-white font-bold">
          Code Iframe !
        </button>

        <div className="purple_border p-8 border border-black ">
          <textarea
            rows="2"
            cols="50"
            readOnly
            value={generatedIframeCode}
            className="border rounded-md px-3 py-2 mt-2 w-full font-bold"
          ></textarea>
        </div>
      </div>

      <div className="flex items-center justify-center gap-8">
        <button
          onClick={copyToClipboard}
          className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded mt-2"
        >
          {copied ? "Copié !" : "Copier le code"}
        </button>
      </div>
      <div></div>
    </>
  );
};

export default IframeCode;

import React from "react";
import moment from "moment";
import {
  Chip,
  IconButton,
  Tooltip,
  Typography,
} from "@material-tailwind/react";
import {
  EyeIcon,
  PencilIcon,
  TrashIcon,
  CodeBracketSquareIcon,
  ChartBarIcon,
} from "@heroicons/react/24/solid";
import SimulateurTaxi from "../TaxiWidget/SimulateurTaxi";
import Modal from "../../../Component/Modal/Modal";
import IframeCode from "../TaxiWidget/IframeCode";
import Statistiques from "../TaxiWidget/Statistiques";
import RightModal from "../TaxiWidget/RightModal";
import EditWidget from "../TaxiWidget/EditWidget";
import Skeleton from "../Loader/Skeleton";
import Lottie from "lottie-react";
import LOCALVTC from "../../../asset/LocalVTC.json";

const ListingTaxi = ({
  widgets,
  isModalOpen,
  isEditModalOpen,
  isCodeModalOpen,
  isStatModalOpen,
  toggleModal,
  toggleCodeModal,
  toggleStatModal,
  toggleEditModal,
  handleDeleteWidgetTaxi,
  loading,
}) => {
  return (
    <>
      {loading ? (
        <tr>
          <td colSpan="8" className="text-center w-full">
            <Skeleton />
          </td>
        </tr>
      ) : widgets.length > 0 ? (
        widgets.map(({ _id, title, type, isOnline, createdAt }, index) => {
          const formattedDate = moment(createdAt).format("DD/MM/YYYY");
          const isLast = index === widgets?.length - 1;
          const classes = isLast
            ? "p-4"
            : "p-4 border-b border-blue-gray-50 text-center items-center";

          return (
            <tr
              key={_id}
              className="odd:bg-gray-50 even:bg-slate-50 hover:bg-black hover:bg-opacity-5 text-center"
            >
              <td className={classes}>
                <div className="flex items-center gap-3 justify-center">
                  <div className="flex flex-col">
                    <Typography
                      variant="small"
                      color="blue-gray"
                      className="font-bold uppercase"
                    >
                      {title}
                    </Typography>
                  </div>
                </div>
              </td>
              <td className={classes}>
                <div className="flex flex-col">
                  <Typography
                    variant="small"
                    color="blue-gray"
                    className="font-normal"
                  >
                    <Lottie
                      animationData={LOCALVTC}
                      className="mx-auto h-10 w-auto"
                      loop={true}
                    />
                  </Typography>
                </div>
              </td>
              <td className={`${classes}  flex items-center justify-center`}>
                <div className="w-max">
                  <Chip
                    variant="ghost"
                    size="sm"
                    value={isOnline ? "Actif" : "Désactivé"}
                    color={isOnline ? "green" : "blue-gray"}
                  />
                </div>
              </td>
              <td className={classes}>
                <Typography
                  variant="small"
                  color="blue-gray"
                  className="font-normal"
                >
                  🗓️ {formattedDate}
                </Typography>
              </td>

              <td className={classes}>
                <Tooltip content="Statistiques">
                  <IconButton
                    variant="text"
                    onClick={() => toggleStatModal(_id)}
                  >
                    <ChartBarIcon className="h-4 w-4" />
                  </IconButton>
                </Tooltip>
                {isStatModalOpen[_id] && (
                  <Modal
                    isOpen={isStatModalOpen[_id]}
                    onClose={() => toggleStatModal(_id)}
                  >
                    <Statistiques widgetId={_id} />
                  </Modal>
                )}
              </td>

              <td className={classes}>
                <Tooltip content="Visualiser le widget">
                  <IconButton variant="text" onClick={() => toggleModal(_id)}>
                    <EyeIcon className="h-4 w-4" />
                  </IconButton>
                </Tooltip>
                {isModalOpen[_id] && (
                  <Modal
                    isOpen={isModalOpen[_id]}
                    onClose={() => toggleModal(_id)}
                  >
                    <SimulateurTaxi widgetId={_id} />
                  </Modal>
                )}
              </td>
              <td className={classes}>
                <Tooltip content="Code d'intégration">
                  <IconButton
                    variant="text"
                    onClick={() => toggleCodeModal(_id)}
                  >
                    <CodeBracketSquareIcon className="h-4 w-4" />
                  </IconButton>
                </Tooltip>
                {isCodeModalOpen[_id] && (
                  <Modal
                    isOpen={isCodeModalOpen[_id]}
                    onClose={() => toggleCodeModal(_id)}
                  >
                    <IframeCode widgetId={_id} />
                  </Modal>
                )}
              </td>
              <td className={classes}>
                <IconButton variant="text" onClick={() => toggleEditModal(_id)}>
                  <PencilIcon className="h-4 w-4" />
                </IconButton>
                {isEditModalOpen[_id] && (
                  <RightModal
                    key={_id}
                    widgetId={_id}
                    editMode={true}
                    type="Widget Taxi"
                    onClose={() => toggleEditModal(_id)}
                  >
                    <EditWidget widgetId={_id} />
                  </RightModal>
                )}
              </td>
              <td className={classes}>
                <Tooltip content="Supprimer le widget">
                  <IconButton
                    variant="text"
                    className="hover:text-red-400"
                    onClick={() => handleDeleteWidgetTaxi(_id)}
                  >
                    <TrashIcon className="h-4 w-4" />
                  </IconButton>
                </Tooltip>
              </td>
            </tr>
          );
        })
      ) : null}
    </>
  );
};

export default ListingTaxi;

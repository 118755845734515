import React, { useState, useEffect } from "react";
import Product from "./data/products.json";
import axios from "axios";
import { Tooltip } from "@material-tailwind/react";
import { InformationCircleIcon } from "@heroicons/react/24/solid";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleArrowRight } from "@fortawesome/free-solid-svg-icons";

const DemenageurWidget = ({ widgetId }) => {
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedCategory, setSelectedCategory] = useState("Tous");
  const [quantities, setQuantities] = useState({});
  const [searchTerm, setSearchTerm] = useState("");
  const [widgets, setWidgets] = useState([]);
  const [inventaire, setInventaire] = useState(false);
  const [clientInfo, setClientInfo] = useState({});
  const [showForm, setShowForm] = useState(false);

  useEffect(() => {
    setIsLoading(false);
  }, []);

  const handleCategoryClick = (categoryName) => {
    setSelectedCategory(categoryName);
    setSearchTerm("");
  };

  const handleQuantityChange = (productName, delta) => {
    setQuantities((prev) => ({
      ...prev,
      [productName]: Math.max((prev[productName] || 0) + delta, 0),
    }));
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setClientInfo((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const filteredProducts = Product.categories
    .flatMap((category) =>
      selectedCategory === "Tous" || category.name === selectedCategory
        ? category.products
        : []
    )
    .filter((product) =>
      product.name.toLowerCase().includes(searchTerm.toLowerCase())
    );

  const totalVolume = Product.categories.reduce((total, category) => {
    return (
      total +
      category.products.reduce((categoryTotal, product) => {
        return (
          categoryTotal + (quantities[product.name] || 0) * product.volume_m3
        );
      }, 0)
    );
  }, 0);

  useEffect(() => {
    const fetchWidget = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/widgets/demenageur/getOneWidget/${widgetId}`
        );
        if (response.status === 200) {
          setWidgets(response.data.widget);
        } else {
          console.error("Erreur lors de la récupération des widgets");
        }
      } catch (error) {
        setIsError(true);
      }
    };

    fetchWidget();
  }, [widgetId]);

  const inventoryProducts = Object.keys(quantities).filter(
    (productName) => quantities[productName] > 0
  );

  const handleFormSubmit = (e) => {
    e.preventDefault();

    // Construire l'inventaire et le volume total
    const inventoryDetails = inventoryProducts
      .map((productName) => {
        const product = Product.categories
          .flatMap((category) => category.products)
          .find((product) => product.name === productName);

        const quantity = quantities[productName];
        const volume = (quantity * product.volume_m3).toFixed(2);

        return `${productName}: ${quantity} pièces, ${volume} m³`;
      })
      .join("\n");

    const totalVolumeStr = totalVolume.toFixed(2);

    // Construire le contenu de l'email
    const emailBody = `
Bonjour,

Je souhaiterais obtenir un devis pour mon futur déménagement. Vous trouverez ci-dessous les détails nécessaires à l'établissement de ce devis.

INFORMATIONS PERSONNELLES :

Nom : ${clientInfo.name}
Téléphone : ${clientInfo.phoneNumber}
Email : ${clientInfo.email}

ADRESSE DE DÉPART :

Adresse : ${clientInfo.currentAddress}
Code postal : ${clientInfo.currentPostalCode}
Ville : ${clientInfo.currentCity}
Surface : ${clientInfo.surface ? clientInfo.surface + " m²" : "Non spécifiée"}

ADRESSE D'ARRIVÉE :

Code postal : ${clientInfo.newPostalCode}
Ville : ${clientInfo.newCity}
Statut : ${
      clientInfo.ownershipStatus ? clientInfo.ownershipStatus : "Non spécifié"
    }

DATE DE DÉMÉNAGEMENT :

${clientInfo.moveDate}

DÉTAILS SUPPLÉMENTAIRES CONCERNANT LE DÉMÉNAGEMENT :

${
  clientInfo.additionalDetails
    ? clientInfo.additionalDetails
    : "Aucune précision fournie"
}

INVENTAIRE ET VOLUME TOTAL ESTIMÉ :

${inventoryDetails}

VOLUME TOTAL : ${totalVolumeStr} m³

Je reste à votre disposition pour toute information complémentaire.

Cordialement,

${clientInfo.name}
`;

    const mailtoLink = `mailto:${
      widgets.contactMail
    }?subject=Demande de devis déménagement&body=${encodeURIComponent(
      emailBody
    )}`;

    window.location.href = mailtoLink;
  };

  const handleDeleteProduct = (productName) => {
    setQuantities((prevQuantities) => {
      const updatedQuantities = { ...prevQuantities };
      delete updatedQuantities[productName];
      return updatedQuantities;
    });
  };

  return (
    <>
      {isError ? (
        <div className="flex items-center justify-center h-screen w-screen antialiased text-center bg-stone-100 text-gray-800 font-openSans">
          <article className="mx-auto max-w-screen-md p-12 items-center">
            <h3 className="text-3xl font-light mb-12">
              Le simulateur est désactivé !
            </h3>
            <p className="text-xl text-gray-800 mb-8">
              Merci de contacter notre support{" "}
              <a
                className="text-blue-800 hover:text-blue-700 mb-8"
                href="https://local.fr"
              >
                Local.fr
              </a>{" "}
              pour plus d'informations.
            </p>
            <p className="font-bold">Simulateur de volume avec Local.fr</p>
          </article>
        </div>
      ) : (
        <div
          className="flex flex-col gap-4 justify-around items-center w-full bg-white rounded-lg border-4"
          style={{ borderColor: widgets?.backgroundColor }}
        >
          <div
            className="head flex justify-between items-center w-full p-4"
            style={{ backgroundColor: widgets?.backgroundColor }}
          >
            <div className="search w-[80%]">
              {!inventaire && !showForm && (
                <input
                  type="text"
                  placeholder="Rechercher un produit"
                  className="p-2 border border-gray-300 w-full rounded-lg"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
              )}
            </div>
            <div className="inventory">
              {!showForm && (
                <button
                  className="p-2 rounded-lg"
                  style={{ color: widgets?.labelColor }}
                  onClick={() => setInventaire(!inventaire)}
                >
                  {inventaire ? "Retour à la liste" : "Mon Inventaire"}
                </button>
              )}
            </div>
          </div>

          {showForm ? (
            <div className="form-container w-full p-4 bg-gray-100 rounded-lg mt-4">
              <h3 className="text-lg font-bold mb-4">Demande de devis</h3>
              <form onSubmit={handleFormSubmit} className="flex flex-col gap-4">
                <div>
                  <label>Votre logement actuel</label>
                  <input
                    type="text"
                    name="currentAddress"
                    placeholder="Adresse actuelle"
                    className="p-2 border border-gray-300 w-full rounded-lg"
                    onChange={handleInputChange}
                  />
                </div>

                <div className="grid grid-cols-3 gap-4">
                  <div>
                    <label>Code postal*</label>
                    <input
                      type="text"
                      name="currentPostalCode"
                      className="p-2 border border-gray-300 w-full rounded-lg"
                      onChange={handleInputChange}
                      required
                    />
                  </div>
                  <div>
                    <label>Ville*</label>
                    <input
                      type="text"
                      name="currentCity"
                      className="p-2 border border-gray-300 w-full rounded-lg"
                      onChange={handleInputChange}
                      required
                    />
                  </div>
                  <div>
                    <label>Surface (m2)</label>
                    <input
                      type="number"
                      name="surface"
                      className="p-2 border border-gray-300 w-full rounded-lg"
                      onChange={handleInputChange}
                    />
                  </div>
                </div>

                <div>
                  <label>Votre nouveau logement</label>
                  <div className="grid grid-cols-3 gap-4">
                    <div>
                      <label>Code postal*</label>
                      <input
                        type="text"
                        name="newPostalCode"
                        className="p-2 border border-gray-300 w-full rounded-lg"
                        onChange={handleInputChange}
                        required
                      />
                    </div>
                    <div>
                      <label>Ville*</label>
                      <input
                        type="text"
                        name="newCity"
                        className="p-2 border border-gray-300 w-full rounded-lg"
                        onChange={handleInputChange}
                        required
                      />
                    </div>
                    <div>
                      <label>Propriétaire / Locataire</label>
                      <input
                        type="text"
                        name="ownershipStatus"
                        placeholder="Propriétaire ou Locataire"
                        className="p-2 border border-gray-300 w-full rounded-lg"
                        onChange={handleInputChange}
                      />
                    </div>
                  </div>
                </div>
                <div>
                  <label>Vos informations</label>
                  <div className="grid grid-cols-3 gap-4">
                    <input
                      type="text"
                      name="name"
                      placeholder="Nom*"
                      className="p-2 border border-gray-300 w-full rounded-lg"
                      onChange={handleInputChange}
                      required
                    />
                    <input
                      type="text"
                      name="phoneNumber"
                      placeholder="Numéro de téléphone*"
                      className="p-2 border border-gray-300 w-full rounded-lg mt-2"
                      onChange={handleInputChange}
                      required
                    />
                    <input
                      type="email"
                      name="email"
                      placeholder="E-mail*"
                      className="p-2 border border-gray-300 w-full rounded-lg mt-2"
                      onChange={handleInputChange}
                      required
                    />
                  </div>
                </div>
                <div>
                  <label>Date de déménagement*</label>
                  <input
                    type="date"
                    name="moveDate"
                    className="p-2 border border-gray-300 w-full rounded-lg"
                    onChange={handleInputChange}
                    required
                  />
                </div>
                <div>
                  <label>Précisions sur le déménagement</label>
                  <textarea
                    name="additionalDetails"
                    placeholder="Précisions à apporter sur votre déménagement"
                    className="p-2 border border-gray-300 w-full rounded-lg"
                    onChange={handleInputChange}
                  ></textarea>
                </div>
                <div className="flex justify-between">
                  <button
                    className="text-white p-2 rounded-lg"
                    style={{ backgroundColor: widgets?.btnColor }}
                    onClick={() => setShowForm(false)}
                  >
                    Revenir en arrière
                  </button>

                  <button
                    type="submit"
                    className="text-white p-2 rounded-lg"
                    style={{ backgroundColor: widgets?.btnColor }}
                    onClick={handleFormSubmit}
                  >
                    Soumettre
                  </button>
                </div>
              </form>
            </div>
          ) : inventaire ? (
            <div className="inventory-list flex flex-col items-center w-full p-5 h-[450px] overflow-y-scroll">
              {inventoryProducts.length > 0 ? (
                inventoryProducts.map((productName) => (
                  <div
                    key={productName}
                    className="product w-full bg-white p-4 rounded-lg border shadow-md mb-2 flex justify-between items-center "
                  >
                    <div className="content flex items-center justify-center gap-5">
                      <img
                        src="https://via.placeholder.com/50"
                        alt={productName}
                      />
                      <p className="text-center">{productName}</p>
                    </div>

                    <div className="actions flex items-center justify-center gap-4">
                      <div className="flex items-center gap-2">
                        <button
                          className="rounded-full w-[25px] h-[25px] flex items-center justify-center cursor-pointer bg-gray-300"
                          onClick={() => handleQuantityChange(productName, -1)}
                        >
                          <span className="text-center">-</span>
                        </button>
                        <span
                          className="rounded-full p-2"
                          style={{
                            backgroundColor: widgets?.btnColor,
                            color: widgets?.labelColor,
                          }}
                        >
                          {quantities[productName]}
                        </span>
                        <button
                          className="rounded-full w-[25px] h-[25px] flex items-center justify-center cursor-pointer bg-gray-300"
                          onClick={() => handleQuantityChange(productName, 1)}
                        >
                          <span className="text-center">+</span>
                        </button>
                      </div>

                      <div className="total-volume flex items-center">
                        Volume total :
                        <span
                          className="rounded-full p-1 ml-2"
                          style={{
                            backgroundColor: widgets?.btnColor,
                            color: widgets?.labelColor,
                          }}
                        >
                          {(
                            quantities[productName] *
                            Product.categories
                              .flatMap((category) => category.products)
                              .find((product) => product.name === productName)
                              .volume_m3
                          ).toFixed(2)}{" "}
                          m3
                        </span>
                      </div>

                      <button
                        className="text-red-500 ml-4"
                        onClick={() => handleDeleteProduct(productName)}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth="2"
                          stroke="currentColor"
                          className="w-6 h-6"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M6 18L18 6M6 6l12 12"
                          />
                        </svg>
                      </button>
                    </div>
                  </div>
                ))
              ) : (
                <p>Votre inventaire est vide.</p>
              )}
            </div>
          ) : (
            <>
              <div className="filter flex flex-wrap gap-4 md:gap-8 justify-center w-full pb-4 shadow-lg">
                <p
                  onClick={() => handleCategoryClick("Tous")}
                  className={
                    selectedCategory === "Tous"
                      ? "font-bold cursor-pointer"
                      : "cursor-pointer"
                  }
                >
                  Tous
                  <span className="text-sm bg-gray-200 rounded-full px-2">
                    {Product.categories.reduce(
                      (acc, category) => acc + category.products.length,
                      0
                    )}
                  </span>
                </p>
                {Product.categories.map((category) => (
                  <p
                    key={category.name}
                    onClick={() => handleCategoryClick(category.name)}
                    className={
                      selectedCategory === category.name
                        ? "font-bold cursor-pointer"
                        : "cursor-pointer"
                    }
                  >
                    {category.name}
                    <span className="text-sm bg-gray-200 rounded-full px-2">
                      {category.products.length}
                    </span>
                  </p>
                ))}
              </div>

              {isLoading ? (
                <div className="flex justify-center items-center h-[200px]">
                  <p>Chargement des produits...</p>
                </div>
              ) : (
                <div className="products flex flex-wrap gap-4 w-full p-5 items-center justify-center h-[450px] overflow-y-scroll">
                  {filteredProducts.length > 0 ? (
                    filteredProducts.map((product) => (
                      <div
                        key={product.name}
                        className="product flex flex-col justify-between w-full sm:w-[48%] md:w-[30%] lg:w-[24%] bg-white p-4 rounded-lg border shadow-md h-[200px]"
                      >
                        <div className="content flex flex-col items-center justify-center gap-3 flex-grow">
                          {product.image ? (
                            <img
                              src={`${process.env.REACT_APP_API_URL}/${product.image}`}
                              alt={product.name}
                              className="w-[50px] h-[50px]"
                            />
                          ) : (
                            <img
                              src="https://via.placeholder.com/50"
                              alt={product.name}
                            />
                          )}
                          <p className="text-center">{product.name}</p>
                        </div>

                        <div className="select flex gap-3 items-center justify-center mt-3">
                          <div
                            className="negatif rounded-full w-[25px] h-[25px] flex items-center justify-center cursor-pointer"
                            style={{
                              backgroundColor: widgets?.backgroundColor,
                              color: widgets?.labelColor,
                            }}
                            onClick={() =>
                              handleQuantityChange(product.name, -1)
                            }
                          >
                            <p className="text-center text-white">-</p>
                          </div>
                          <div className="quantity">
                            {quantities[product.name] || 0}
                          </div>
                          <div
                            className="positif rounded-full w-[25px] h-[25px] flex items-center justify-center cursor-pointer"
                            style={{
                              backgroundColor: widgets?.backgroundColor,
                              color: widgets?.labelColor,
                            }}
                            onClick={() =>
                              handleQuantityChange(product.name, 1)
                            }
                          >
                            <p className="text-center text-white">+</p>
                          </div>
                        </div>
                      </div>
                    ))
                  ) : (
                    <div className="flex justify-center items-center h-[200px]">
                      <p>Aucun résultat trouvé</p>
                    </div>
                  )}
                </div>
              )}
            </>
          )}

          {!showForm && !inventaire && (
            <div
              className="footer flex justify-between items-center w-full p-4"
              style={{
                backgroundColor: widgets?.backgroundColor,
                color: widgets?.labelColor,
              }}
            >
              <div className="volumeTotal flex items-center justify-center gap-6">
                <p>
                  Volume total :{" "}
                  <span className="bg-white p-2 rounded-lg text-black ml-2 font-bold">
                    {totalVolume.toFixed(2)} M3
                  </span>
                </p>

                {totalVolume > 0 && widgets.priceActive && (
                  <div className="flex items-center justify-center">
                    Prix total :
                    <span className="bg-white p-2 rounded-lg text-black ml-2 font-bold">
                      {(
                        totalVolume * widgets.pricePerM3 +
                        widgets.voyageVidePrice
                      ).toFixed(2)}
                      €
                    </span>
                    <Tooltip
                      content={`Prix du voyage vide : ${widgets.voyageVidePrice}€`}
                    >
                      <InformationCircleIcon className="h-6 w-6 ml-4" />
                    </Tooltip>
                  </div>
                )}
              </div>
              <div className="button w-[30%] flex items-center justify-center">
                <button
                  className="text-white p-2 rounded-lg px-5"
                  style={{ backgroundColor: widgets?.btnColor }}
                  onClick={() => setShowForm(true)}
                >
                  Demander un devis{" "}
                  <FontAwesomeIcon icon={faCircleArrowRight} />
                </button>
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default DemenageurWidget;

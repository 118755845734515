import React, { useState } from "react";
import classNames from "classnames";
import EditWidget from "./EditWidget";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowAltCircleRight } from "@fortawesome/free-solid-svg-icons";
import EditWidgetDemenageur from "../DemenageurWidget/EditWidgetDemenagement";

const RightModal = ({ editMode, tutoMode, widgetId, onClose, type }) => {
  const [isVisible, setIsVisible] = useState(editMode);

  const toggleTutorial = () => {
    setIsVisible(!isVisible);
  };

  return (
    <div className="fixed top-0 right-0 z-20 w-2/3">
      {isVisible && (
        <div
          className={classNames(
            "transition-all duration-500",
            { "opacity-100 translate-x-0 ": isVisible },
            { "opacity-0 translate-x-full": !isVisible }
          )}
          style={{
            minWidth: "100%",
            minHeight: "100vh",
            background: "#fcfbfb",
            boxShadow: "0 0 10px black",
          }}
        >
          <div className="flex items-center justify-center content-center h-dvh relative">
            {editMode && type === "Widget Taxi" && (
              <EditWidget widgetId={widgetId} />
            )}
            {editMode && type === "Widget Demenageur" && (
              <EditWidgetDemenageur widgetId={widgetId} />
            )}
            <button
              className="absolute top-4 left-4 text-black text-xl"
              onClick={tutoMode ? toggleTutorial : onClose}
            >
              <FontAwesomeIcon icon={faArrowAltCircleRight} />
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default RightModal;

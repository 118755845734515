import React from "react";
import { useParams } from "react-router-dom";
import DemenageurWidget from "./DemenageurWidget";

const Iframe = () => {
  const { widgetId } = useParams();

  return <DemenageurWidget widgetId={widgetId} />;
};

export default Iframe;

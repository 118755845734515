import { faArrowAltCircleLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { toast } from "sonner";
import Modal from "../../../Component/Modal/Modal";
import IframeCode from "./iframeCodeDemenagement";

const CreateWidgetDemenageur = ({ userId }) => {
  const user = useSelector((state) => state.userReducer);
  const [isLoading, setIsLoading] = useState(false);
  const [widgetId, setWidgetId] = useState("");
  const [etages, setEtages] = useState([{ etage: 0, prix: 0 }]);

  const [widgetData, setWidgetData] = useState({
    userId: userId,
    title: "",
    priceActive: "true",
    pricePerM3: 0,
    serviceEmballe: 0,
    etages: [],
    assurance: 0,
    objetsLourds: 0,
    voyageVidePrice: 0,
    isOnline: "online",
    type: "Widget Demenageur",
    btnColor: "",
    backgroundColor: "",
    labelColor: "",
  });

  const handleInputChange = (e) => {
    const value =
      e.target.type === "number" ? Number(e.target.value) : e.target.value;
    setWidgetData({ ...widgetData, [e.target.name]: value });
  };

  const ajouterEtage = () => {
    setEtages([...etages, { etage: etages.length, prix: 0 }]);
  };

  const supprimerEtage = (index) => {
    const updatedEtages = etages.filter((_, i) => i !== index);
    setEtages(updatedEtages);
  };

  const handleEtageChange = (index, value) => {
    const updatedEtages = etages.map((item, i) =>
      i === index ? { ...item, prix: value } : item
    );
    setEtages(updatedEtages);
  };

  const finalWidgetData = {
    ...widgetData,
    etages,
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/widgets/demenageur/create`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(finalWidgetData),
        }
      );
      if (response.ok) {
        const data = await response.json();
        toast.success("Widget créé avec succès");

        const widgetId = data.widget._id;
        setWidgetId(widgetId);
      } else {
        switch (response.status) {
          case 401:
            toast.error("Vous n'êtes pas connecté");
            break;
          case 400:
            toast.error("Attention, un widget existe déjà avec ce nom.");
            break;
          default:
            toast.error("Une erreur est survenue");
            break;
        }
      }
    } catch (error) {
      console.error("Error:", error);
      setIsLoading(false);
    }
  };

  return (
    <div className="p-4 w-full flex gap-10">
      <div className="w-full">
        <Link to={`/`}>
          <button className="bg-black text-white px-4 py-2 rounded-md hover:bg-gray-600 my-1 w-[200px] flex justify-center items-center gap-2">
            <FontAwesomeIcon icon={faArrowAltCircleLeft} />
            <p>Revenir à la liste</p>
          </button>
        </Link>
        {user.user.isPremium ? (
          <form
            onSubmit={handleSubmit}
            className="space-y-4 flex justify-center items-center mt-5"
          >
            <div
              className="shadow-2xl rounded p-9 pt-6 pb-8 mb-4 flex flex-col my-2 w-[80%] mt-2"
              style={{
                backgroundColor: widgetData.backgroundColor || "bg-white",
                color: widgetData.labelColor || "black",
              }}
            >
              <div className="-mx-3 md:flex mb-3">
                <div className="md:w-full px-3 flex flex-col items-center justify-center">
                  <label
                    className="block uppercase tracking-wide text-grey-darker text-sm mb-2 text-center font-extrabold"
                    htmlFor="grid-password"
                  >
                    Donner un titre a votre simulateur *
                  </label>
                  <input
                    className="text-center block w-3/4 rounded-md border-0 p-2 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    id="grid-password"
                    type="texte"
                    name="title"
                    placeholder="Code sage - Entreprise"
                    value={widgetData.title}
                    onChange={handleInputChange}
                    required
                  />
                </div>
              </div>

              <div className="-mx-3 md:flex mb-3">
                <div className="md:w-full px-3 flex flex-col items-center justify-center">
                  <label
                    className="block uppercase tracking-wide text-grey-darker text-sm mb-2 text-center font-extrabold"
                    htmlFor="grid-password"
                  >
                    Afficher le prix ?
                  </label>
                  <select
                    className="text-center block w-3/4 rounded-md border-0 p-2 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    id="grid-password"
                    name="priceActive"
                    value={widgetData.priceActive}
                    onChange={handleInputChange}
                  >
                    <option value="true">Oui</option>
                    <option value="false">Non</option>
                  </select>
                </div>
              </div>

              {widgetData.priceActive === "true" ? (
                <div className="bg-blue-gray-50 border rounded-lg m-5">
                  <div className="-mx-3 md:flex mb-3">
                    <div className="md:w-full px-3 flex flex-col items-center justify-center">
                      <label
                        className="block uppercase tracking-wide text-grey-darker text-sm mb-2 text-center font-extrabold"
                        htmlFor="grid-password"
                      >
                        Prix au m3 *
                      </label>
                      <input
                        className="text-center block w-3/4 rounded-md border-0 p-2 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        id="grid-password"
                        type="number"
                        name="pricePerM3"
                        placeholder="Prix au m3"
                        value={widgetData.pricePerM3}
                        onChange={handleInputChange}
                        required
                      />
                    </div>
                  </div>
                  <div className="-mx-3 md:flex mb-3">
                    <div className="md:w-full px-3 flex flex-col items-center justify-center">
                      <label
                        className="block uppercase tracking-wide text-grey-darker text-sm mb-2 text-center font-extrabold"
                        htmlFor="grid-password"
                      >
                        Tarif Voyage à vide *
                      </label>
                      <input
                        className="text-center block w-3/4 rounded-md border-0 p-2 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        id="grid-password"
                        type="number"
                        name="voyageVidePrice"
                        placeholder="Prix du voyage a vide"
                        value={widgetData.voyageVidePrice}
                        onChange={handleInputChange}
                        required
                      />
                    </div>
                    <div className="md:w-full px-3 flex flex-col items-center justify-center">
                      <label className="block uppercase tracking-wide text-grey-darker text-sm mb-2 text-center font-extrabold">
                        Service d'emballage (Prix en €)
                      </label>
                      <input
                        className="text-center block w-3/4 rounded-md border-0 p-2 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        type="number"
                        name="serviceEmballe"
                        placeholder="Prix service emballage"
                        value={widgetData.serviceEmballe}
                        onChange={handleInputChange}
                        required
                      />
                    </div>
                  </div>

                  <div className="-mx-3 md:flex mb-3">
                    <div className="md:w-full px-3 flex flex-col items-center justify-center">
                      <label className="block uppercase tracking-wide text-grey-darker text-sm mb-2 text-center font-extrabold">
                        Assurance (Prix en €)
                      </label>
                      <input
                        className="text-center block w-3/4 rounded-md border-0 p-2 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        type="number"
                        name="assurance"
                        placeholder="Prix assurance"
                        value={widgetData.assurance}
                        onChange={handleInputChange}
                        required
                      />
                    </div>
                    <div className="md:w-full px-3 flex flex-col items-center justify-center">
                      <label className="block uppercase tracking-wide text-grey-darker text-sm mb-2 text-center font-extrabold">
                        Objets lourds (Prix en €)
                      </label>
                      <input
                        className="text-center block w-3/4 rounded-md border-0 p-2 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        type="number"
                        name="objetsLourds"
                        placeholder="Prix pour objets lourds"
                        value={widgetData.objetsLourds}
                        onChange={handleInputChange}
                        required
                      />
                    </div>
                  </div>

                  <div className="etages-list">
                    <label className="block uppercase tracking-wide text-grey-darker text-sm mb-2 text-center font-extrabold">
                      Prix par étage
                    </label>
                    {etages.map((etage, index) => (
                      <div
                        key={index}
                        className="flex items-center justify-center gap-4 mb-3"
                      >
                        <span>Étage {etage.etage}:</span>
                        <input
                          className="text-center block w-1/4 rounded-md border-0 p-2 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                          type="number"
                          placeholder="Prix"
                          value={etage.prix}
                          onChange={(e) =>
                            handleEtageChange(index, e.target.value)
                          }
                        />
                        {etages.length > 1 && (
                          <button
                            type="button"
                            className="bg-red-500 text-white px-2 py-1 rounded"
                            onClick={() => supprimerEtage(index)}
                          >
                            Supprimer
                          </button>
                        )}
                      </div>
                    ))}
                    <div className="flex items-center justify-center">
                      <button
                        type="button"
                        className="bg-blue-500 text-white px-2 py-1 rounded mt-3"
                        onClick={ajouterEtage}
                      >
                        + Ajouter un autre étage
                      </button>
                    </div>
                  </div>
                </div>
              ) : null}

              <div className="-mx-3 md:flex mb-3">
                <div className="md:w-full px-3 flex flex-col items-center justify-center">
                  <label
                    className="block uppercase tracking-wide text-grey-darker text-sm mb-2 text-center font-extrabold"
                    htmlFor="grid-password"
                  >
                    Couleur du bouton
                  </label>
                  <input
                    className="text-center block w-3/4 rounded-md border-0 p-2 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    id="grid-password"
                    type="text"
                    name="btnColor"
                    placeholder="Couleur du bouton"
                    value={widgetData.btnColor}
                    onChange={handleInputChange}
                  />
                </div>
              </div>

              <div className="-mx-3 md:flex mb-3">
                <div className="md:w-full px-3 flex flex-col items-center justify-center">
                  <label
                    className="block uppercase tracking-wide text-grey-darker text-sm mb-2 text-center font-extrabold"
                    htmlFor="grid-password"
                  >
                    Couleur de fond
                  </label>
                  <input
                    className="text-center block w-3/4 rounded-md border-0 p-2 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    id="grid-password"
                    type="text"
                    name="backgroundColor"
                    placeholder="Couleur de fond"
                    value={widgetData.backgroundColor}
                    onChange={handleInputChange}
                  />
                </div>
              </div>

              <div className="-mx-3 md:flex mb-3">
                <div className="md:w-full px-3 flex flex-col items-center justify-center">
                  <label
                    className="block uppercase tracking-wide text-grey-darker text-sm mb-2 text-center font-extrabold"
                    htmlFor="grid-password"
                  >
                    Couleur du texte
                  </label>
                  <input
                    className="text-center block w-3/4 rounded-md border-0 p-2 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    id="grid-password"
                    type="text"
                    name="labelColor"
                    placeholder="Couleur du texte"
                    value={widgetData.labelColor}
                    onChange={handleInputChange}
                  />
                </div>
              </div>

              <div className="-mx-3 md:flex mb-3">
                <div className="md:w-full px-3 flex flex-col items-center justify-center">
                  <label
                    className="block uppercase tracking-wide text-grey-darker text-sm mb-2 text-center font-extrabold"
                    htmlFor="grid-password"
                  >
                    Adresse mail de contact
                  </label>
                  <input
                    className="text-center block w-3/4 rounded-md border-0 p-2 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    id="contactMail"
                    type="email"
                    name="contactMail"
                    placeholder="Adresse mail de contact"
                    value={widgetData.contactMail}
                    onChange={handleInputChange}
                  />
                </div>
              </div>
              <div className="flex items-center justify-center mt-6">
                <button
                  className="bg-black text-white px-4 py-2 rounded-md hover:bg-gray-600 my-1 w-[200px] flex justify-center items-center gap-2"
                  style={{
                    backgroundColor: widgetData.btnColor || "bg-white",
                    color: widgetData.labelColor || "white",
                  }}
                >
                  {isLoading ? "Chargement..." : "Créer le widget"}
                </button>
              </div>
            </div>
          </form>
        ) : null}
        {widgetId && (
          <Modal
            title="Code iframe"
            isOpen={true}
            onClose={() => setWidgetId("")}
            children={<IframeCode widgetId={widgetId} />}
          />
        )}
      </div>
    </div>
  );
};

export default CreateWidgetDemenageur;
